@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Fredoka', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Fredoka', sans-serif;
}

div, button, ul, li, span, a, p {
  box-sizing: border-box;
}

main {
  margin: 30px 0;
}