@import '../../DS/breakpoints.scss';
@import '../../DS/colors.scss';

.header {
  box-shadow: 0 2px 5px 3px rgba(#000, 0.2);
  background-color: $color-light-gray;

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__image {
    display: inline-block;
    width: 150px;
  }
}