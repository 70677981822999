@import '../../DS/breakpoints.scss';
@import '../../DS/colors.scss';

.footer {
  padding: 30px 0;
  box-shadow: 0 2px 5px 3px rgba(#000, 0.2);
  background-color: $color-light-gray;

  &__image {
    display: inline-block;
    width: 150px;
  }

  &__menu {
    display: inline-block;
    padding: 0;
    margin: 25px 0;
    vertical-align: top;

    @include smallerThan($md) {
      width: 100%;
    }

    &__item {
      display: block;
      margin: 0 20px;
    }

    &__item__link {
      font-size: 16px;
      text-decoration: none;
      color: #000;
    }
  }
}