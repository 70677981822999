@import '../../DS/colors.scss';
@import '../../DS/buttons.scss';

.instagram-profile {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 24px;
  background-color: $color-light-gray;
  box-shadow: 0 2px 5px 3px rgba($color-light-black, 0.4);
  border-radius: 10px;
  text-align: center;
}

.instagram-profile__image-container {
  display: inline-block;
  width: 140px;
}

.instagram-profile__image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.instagram-profile__info {
  text-align: center;
}

.instagram-profile__title {
  font-size: 1.5rem;
  margin: 16px 0;
}

.instagram-profile__description {
  font-size: 1rem;
  margin: 16px 0;
}

.instagram-profile__action-container {
  margin-top: 16px;
}

.instagram-profile__link {
  @extend .button-primary;
}
