@import '../../DS/colors.scss';
@import '../../DS/buttons.scss';

.book-card {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 24px;
  background-color: $color-light-gray;
  box-shadow: 0 2px 5px 3px rgba($color-light-black, 0.4);
  border-radius: 10px;
  text-align: left;

  &__image-container {
    display: inline-block;
    width: 140px;
  }

  &__image {
    max-width: 100%;
  }

  &__info {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 140px);
    padding-left: 24px;
  }

  &__title {
    margin: 0 0 12px 0;
    font-size: 24px;
  }

  &__description {
    font-size: 14px;
  }

  &__author {
    font-size: 16px;
  }

  &__action-container {
    text-align: right;
  }

  &__buy-link {
    @extend .button-primary;
  }
}
