@import "./colors.scss";

.button-primary {
  background-color: $color-martinique;
  display: inline-block;
  padding: 12px 24px;
  border-radius: 5px;
  color: $color-white;
  text-decoration: none;

  &:hover {
    background-color: $color-martinique;
    color: $color-white;
  }
}
