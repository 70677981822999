@import '../../DS/colors.scss';
@import '../../DS/buttons.scss';

.about {
  text-align: left;

  &__buy-link {
    @extend .button-primary;
  }
}
